export default {
  aboutMe: 'درباره‌ی من',
  arash: 'آرش',
  arashKadkhodaei: 'آرش کدخدائی',
  continueReadig: 'ادمه‌ی نوشته',
  feed: 'فید',
  footerMessage: 'طراحی با ♥ توسط آرش کدخدائی الیادرانی (@slasharash)',
  mainPage: 'برگه‌ی اصلی',
  newer: 'جدیدتر',
  pageNotFound: 'صفحه پیدا نشد',
  older: 'قدیمی‌تر',
  postWithThisTag: 'نوشته با این برچسب',
  tagName: (tag: string) => `برچسب «${tag}»`,
  tags: 'برچسب‌ها',
  youCanVisitFollowigPages: 'شما می‌توانید برگه‌های زیر را مشاهده کنید',
}
